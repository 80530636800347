'use client';

import useUser from '@alltrails/context/hooks/useUser';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import CarouselDisplayTrigger from '@alltrails/amplitude/enums/CarouselDisplayTrigger';
import UpsellTrigger from '@alltrails/amplitude/enums/UpsellTrigger';
import { useExperiments } from '@alltrails/experiments';

const GenericPostAuthUpsellModal = dynamic(() => import('@alltrails/upsell/components/GenericPostAuthUpsell/PostAuthUpsellModal'), {
  ssr: false
});

/*
  AppleRedirectUpsell displays the post-auth upsell to non-plus users who authenticated with Apple.
  For Apple auth, the user is taken to a separate page, then redirected back to our site.
  When a user returns, the apple_oauth param is included in search params.
 */

const AppleRedirectUpsell = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  const experiments = useExperiments();
  const isPeakSoftLaunch =
    experiments['rm-peak-soft-launch']?.value === 'peak_default' || experiments['rm-peak-soft-launch']?.value === 'plus_default';

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const isInUpsellTier = isPeakSoftLaunch ? user?.subscriptionTier !== 'peak' : user?.subscriptionTier === 'base';
    if (searchParams.has('apple_oauth') && user && isInUpsellTier) {
      setIsOpen(true);
    }
  }, [isPeakSoftLaunch, user]);

  return (
    <>
      {isOpen && (
        <GenericPostAuthUpsellModal
          isOpen={isOpen}
          onCloseRequest={() => setIsOpen(false)}
          trigger={CarouselDisplayTrigger.CompleteAuthentication}
          upsellTrigger={UpsellTrigger.CompleteAuthentication}
        />
      )}
    </>
  );
};

export default AppleRedirectUpsell;
