'use client';

import Player from '@vimeo/player';
import Button from '@alltrails/denali/components/Button';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import TrailDetailsAction from '@alltrails/amplitude/enums/TrailDetailsAction';
import { useCallback, useState, useEffect, useRef } from 'react';
import type User from '@alltrails/core/types/User';
import useUser from '@alltrails/context/hooks/useUser';
import dynamic from 'next/dynamic';
import CarouselDisplayTrigger from '@alltrails/amplitude/enums/CarouselDisplayTrigger';
import CollParks from '@alltrails/denali/icons/CollParks';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import useBaseBranchUrl from '@alltrails/install-prompt/hooks/useBaseBranchUrl';
import UpsellTrigger from '@alltrails/amplitude/enums/UpsellTrigger';
import SectionHeader from '../SectionHeader';
import styles from './DownloadAppModule.module.scss';

const DownloadOrOpenInTheAppModal = dynamic(() => import('@alltrails/in-app/components/DownloadOrOpenInTheAppModal/DownloadOrOpenInTheAppModal'), {
  ssr: false
});
const SignUpModal = dynamic(() => import('@alltrails/user-auth').then(module => ({ default: module.SignUpModal })), { ssr: false });

type Props = {
  parkId: number;
};

const DownloadAppModule = ({ parkId }: Props) => {
  // *** This component is only shown to en locale ***
  const videoRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<Player | null>(null);
  const hasPausedRef = useRef(false);
  const isVisible = useIsVisible(videoRef, { threshold: 0.5 });
  const isVisibleRef = useRef(false);
  const user = useUser();
  const isMobile = useIsMobileSizedScreen();
  const deeplinkPath = `alltrails://parks/${parkId}`;
  const baseBranchUrl = useBaseBranchUrl();
  const deeplink = `${baseBranchUrl}&$ios_deeplink_path=${deeplinkPath}&$android_deeplink_path=${deeplinkPath}`;
  const [isSignupModalOpen, toggleSignupModal] = useState(false);
  const [isHitTheTrailModalOpen, setIsHitTheTrailModalOpen] = useState(false);

  const handleClickOpenInApp = useCallback(
    (currentUser?: User | null) => {
      if (!currentUser && !user) {
        toggleSignupModal(true);
        return;
      }

      if (isMobile) {
        window.location.assign(deeplink);
      } else {
        setIsHitTheTrailModalOpen(true);
      }
    },
    [isMobile, deeplink, user]
  );

  useEffect(() => {
    isVisibleRef.current = !!isVisible;
  }, [isVisible]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!playerRef.current) {
      playerRef.current = new Player(videoRef.current);

      playerRef.current.on('pause', () => {
        if (isVisibleRef.current) {
          hasPausedRef.current = true;
        }
      });

      playerRef.current.on('play', () => {
        hasPausedRef.current = false;
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('pause');
        playerRef.current.off('play');
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      if (isVisible) {
        // Only play if it wasn't manually paused by the user
        if (!hasPausedRef.current) {
          playerRef.current.play().catch(error => {
            console.error('Error playing video:', error);
          });
        }
      } else {
        // Pause the video when it's not visible
        if (!hasPausedRef.current) {
          playerRef.current.pause();
        }
      }
    }
  }, [isVisible]);

  return (
    <>
      <section className={styles.downloadAppModuleSSection}>
        <div className={styles.titleSection}>
          <div className={styles.parks}>
            <CollParks size="sm" />
            Parks
          </div>
          <SectionHeader title="Headed to a park?" className={styles.title} />
          <SectionHeader title="Take AllTrails with you" className={styles.subtitle} />
          <Button className={styles.desktopButton} testId="open-in-app" text="Open in app" onClick={() => handleClickOpenInApp()} />
        </div>
        <div className={styles.movie}>
          <div className={styles.embedFlex}>
            <div className={styles.embedContainer}>
              <iframe
                ref={videoRef}
                src="https://player.vimeo.com/video/952401576?h=cb37bede0d&amp;badge=0&amp;autoplay=1&amp;muted=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;unmute_button=1"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture;"
                className={styles.embedVid}
                title="Parks Open in App"
              />
            </div>
          </div>
          <script src="https://player.vimeo.com/api/player.js" async />
        </div>
        <Button className={styles.mobileButton} testId="open-in-app-mobile" text="Open in app" onClick={() => handleClickOpenInApp()} />
      </section>
      {isHitTheTrailModalOpen && (
        <DownloadOrOpenInTheAppModal
          deeplink="open"
          isOpen={isHitTheTrailModalOpen}
          onCloseRequest={() => setIsHitTheTrailModalOpen(false)}
          subtitle="Scan the QR code to open  in the app"
          title="Open in the app"
          resourceType="park"
          resourceId={parkId}
          webToAppAction={TrailDetailsAction.OpenInApp}
        />
      )}
      {isSignupModalOpen && (
        <SignUpModal
          closeModal={() => toggleSignupModal(false)}
          isOpen={isSignupModalOpen}
          onSuccess={(userAfterSignUp: User) => {
            toggleSignupModal(false);
            handleClickOpenInApp(userAfterSignUp);
          }}
          hideInstallPrompt
          trigger={CarouselDisplayTrigger.ParkPageAppPromoModule}
          heading="Create your free account"
          upsellTrigger={UpsellTrigger.ParkPageAppPromoModule}
        />
      )}
    </>
  );
};

export default DownloadAppModule;
