'use client';

import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Accordion from '@alltrails/denali/components/Accordion';
import logFAQDropDownClicked from '@alltrails/amplitude/events/logFAQDropDownClicked';
import PageType from '@alltrails/amplitude/enums/PageType';
import styles from './FrequentlyAskedQuestion.module.scss';

type FrequentlyAskedQuestionProps = {
  shouldRenderExpanded: boolean;
  question: string; // This needs to be a string so it's the correct type for the JSON-LD markup
  answer: React.ReactNode;
  pageType: PageType;
  id: number;
};

export default function FrequentlyAskedQuestion({ shouldRenderExpanded, question, answer, id, pageType }: FrequentlyAskedQuestionProps) {
  const [openAccordion, setOpenAccordion] = useState(false);

  const toggleAccordion = useCallback(() => {
    if (!openAccordion) {
      logFAQDropDownClicked({ id: String(id), page_type: pageType });
    }
    setOpenAccordion(prevState => !prevState);
  }, [id, openAccordion, pageType]);

  return (
    <div>
      {shouldRenderExpanded ? (
        <div className={styles.borderBottom}>
          <h3 className={classNames(styles.question, styles.onlyQuestion)}>{question}</h3>
          <div>
            <div className={classNames(styles.answer, styles.onlyAnswer)}>{answer}</div>
          </div>
        </div>
      ) : (
        <div>
          <Accordion
            variant="minimal"
            title={<h3 className={styles.question}>{question}</h3>}
            externalControls={{
              isOpen: openAccordion,
              toggleIsOpen: toggleAccordion
            }}
          >
            <div>
              <div className={styles.answer}>{answer}</div>
            </div>
          </Accordion>
        </div>
      )}
    </div>
  );
}
