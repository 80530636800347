import Review from '@alltrails/core/types/Review';
import { AlgoliaTrail } from '@alltrails/trails';
import { ShareObject } from '@alltrails/sharing';
import { FilterConfig as SharedFilterConfig } from '@alltrails/filters/types/filters';
import { GuideHit } from '@alltrails/search/types/algoliaResultTypes';
import Access from '@alltrails/filters/constants/accessEnum';
import { ActivityType } from '@alltrails/shared/types/activity';
import TrailFeature from '@alltrails/shared/types/trailFeature';
import TrailLength from '@alltrails/shared/types/trailLength';
import TrailDifficulty from '@alltrails/shared/types/trailDifficulty';
import LocationPageAlertType from '@alltrails/amplitude/enums/LocationPageAlertType';
import { River, PhotoHash, LocationBreadcrumb, ParkRanking } from '../shared';

export type LocationDetailsPageProps = {
  details: LocationDetailsApiResponse;
  isBot: boolean;
};

export type ReviewAndTrail = {
  review_snippet: Review;
  trail: AlgoliaTrail;
};

export type FAQTrail = {
  name: string;
  duration: string | null;
  length: number | string;
  elevationGain: number;
  starRating: number | string;
  reviewCount: number;
  slug: string;
};

export type FAQParkAggregatesType = {
  totalTrails: number;
  averageRating: number;
  reviewCount: number;
};

export type FAQTrailType = {
  name: string;
  count: number;
};

export type FAQNationalParkType = {
  name: string;
  slug: string;
  trailCount: number;
  reviewCount: number;
  averageRating: number;
};

export type ActivityFaq = {
  count: number;
  mostPopular: FAQTrail;
};

export type FaqDataType = {
  longestTrail: FAQTrail | null;
  bestTrail: FAQTrail | null;
  mostElevationGainTrails: FAQTrail[] | null;
  popularDifficultTrail: FAQTrail | null;
  faqTrailTypes: FAQTrailType[] | null;
  faqNationalParks?: FAQNationalParkType[] | null;
  faqParkAggregates?: FAQParkAggregatesType | null;
  campingTrails: ActivityFaq | null;
  runningTrails?: ActivityFaq;
  bikingTrails?: ActivityFaq;
};

export type LocationDetailsResponseType = 'poi' | 'park' | 'location';

export type FilterConfig = {
  baseFilters: SharedFilterConfig;
  enabledFilters: string[];
};

type ParkDayHours = {
  open: string | null;
  close: string | null;
  formatted_str: string | null;
};

type ParkHelpfulLink = {
  title: string;
  value: string | null;
  maxLength: number;
};

export type ParkInfoType = {
  parkId: number;
  ranking: ParkRanking | null;
  areaAcres: number | null;
  hours: {
    Monday: ParkDayHours;
    Tuesday: ParkDayHours;
    Wednesday: ParkDayHours;
    Thursday: ParkDayHours;
    Friday: ParkDayHours;
    Saturday: ParkDayHours;
    Sunday: ParkDayHours;
  };
  helpfulLinks: {
    facebook_link: ParkHelpfulLink;
    twitter_link: ParkHelpfulLink;
    contact_email: ParkHelpfulLink;
    website: ParkHelpfulLink;
  };
  phoneNumbers: {
    contact_phone: ParkHelpfulLink;
  };
  hasVisitorInfo?: boolean;
};

export type Tag = ActivityType | TrailFeature | Access | TrailDifficulty;
export type PageFilterType = ActivityType | TrailFeature | TrailDifficulty | TrailLength;

export type SeoLinksSectionKey = 'REGIONS' | 'STATES' | 'TRAILS' | 'PARKS' | 'CITIES';

export type SeoLinks = {
  url: string;
  title: string;
}[];

export type VisitorStats = {
  visitorsCount: string;
  busiestMonth: string;
};

export type LocationDetailsApiResponse = {
  id: number;
  name: string;
  cityName?: string | null;
  stateName: string | null;
  countryName: string;
  title: string;
  resultsTitle: string;
  locationSlug: string;
  rating: {
    value: number;
    total: number;
    count: number;
  };
  photos: PhotoHash[];
  totals: {
    reviews: number;
    photos: number;
    trails: number;
  };
  staticMap: {
    imageUrl: string;
    exploreLink: string;
    title: string;
  };
  geo: {
    latitude: string;
    longitude: string;
  };
  overview: string;
  rivers: River[];
  orderedBreadcrumbList: LocationBreadcrumb[];
  type: LocationDetailsResponseType;
  directionsLink: string;
  shareObject: ShareObject;
  metaDescription: string;
  description: string;
  showExpandableContent: boolean;
  filterConfig: FilterConfig;
  guides: GuideHit[];
  faqData: FaqDataType | null;
  reviewSnippets: ReviewAndTrail[];
  initialTrailResults: AlgoliaTrail[];
  tags: Tag[];
  shareOgImage: {
    url: string | null;
    url_https?: string;
    width?: number;
    height?: number;
  };
  h2SeoTest: string | null;
  localizedLocationSlug: string;
  pointsOfInterest: { name: string; slug: string }[];
  canEdit: boolean;
  seoTestCanonicalUrl: string | null;
  printLink?: string;
  parkInfo?: ParkInfoType; // Only for Parks
  seoLinks?: {
    key: SeoLinksSectionKey;
    data: SeoLinks;
  }[];
  pageFilterUid?: PageFilterType;
  seoTestFeatures: string[];
  associatedLocationSlug?: string; // Only for POIs
  primaryPark?: {
    // Only for POIs
    name: string;
    slug: string;
  };
  metadata?: {
    updated_at: string;
  };
  visitorStats?: VisitorStats;
  parkInfoDescriptionHtml?: string; // Only for Parks
};

export type PlaceType = 'poi' | 'country' | 'state' | 'city' | 'area';

export enum ParkSectionType {
  AlertBanner = 'alert-banner',
  PlanYourVisit = 'plan-your-visit'
}

export enum ParkSectionRel {
  HoursContact = 'park-hours-contact',
  PassesFees = 'park-passes-fees',
  Accessibility = 'park-accessibility'
}

export type ParkSectionLink = {
  rel: ParkSectionRel;
  title: string;
  href: string;
  description?: string;
};

export type ParkSection = {
  sectionType: ParkSectionType;
  priorityType?: string;
  alertBanner?: { bannerType: LocationPageAlertType; heading: string; subheading?: string };
  sectionTitle?: string;
  links?: ParkSectionLink[];
};

export type ParkSectionsApiResponse = {
  sections: ParkSection[];
};

export enum SectionType {
  HoursSummary = 'hours-summary',
  Divider = 'divider',
  Contact = 'contact',
  HoursWeekly = 'hours-weekly',
  PassFee = 'pass-fee',
  SectionHeader = 'section-header',
  TextBlock = 'text-block',
  TextBlockExpandable = 'text-block-expandable'
}

export enum VisitorInfoRel {
  ContactPhone = 'contact-phone',
  External = 'external',
  ParkAccessibility = 'park-accessibility',
  ParkAlerts = 'park-alerts',
  ParkHoursContact = 'park-hours-contact',
  ParkPassesFees = 'park-passes-fees',
  Alerts = 'alerts'
}

export type VisitorInfoLink = {
  title: string;
  description?: string;
  href: string;
  rel: VisitorInfoRel;
};

export type VisitorInfoSection = {
  title?: string;
  description?: string;
  sectionType: SectionType;
  links?: VisitorInfoLink[];
  cost?: string;
  padding?: boolean;
  dayHours?: {
    day: string;
    schedule: string;
  }[];
};

export type VisitorInfoModalResponse = {
  sections: VisitorInfoSection[];
};

export type ParkAlert = {
  id: number;
  title: string;
  description: string;
};

export type ParkAlertSection = {
  sectionType: 'caution' | 'information' | 'closure' | 'danger';
  alerts: ParkAlert[];
};

export type ParkAlertsApiResponse = {
  sections: ParkAlertSection[];
  links: {
    rel: string;
    href: string;
  }[];
};
