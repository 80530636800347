import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logFAQDropDownClickedParams = {
  id: string;
  page_type?: PageType;
};

/**
 * User clicks a drop down to see the answer to an FAQ.
 * @param params - Attributes to log with the amplitude event
 * @param params.id - ID for the object the FAQ are associated with, could be a trail, park, location, etc.
 * @param params.page_type - The type of content page on which the section was viewed.
 */
const logFAQDropDownClicked = ({ id, page_type }: logFAQDropDownClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('faq drop down clicked', { id, 'page type': page_type }, callback);
};

export default logFAQDropDownClicked;
