import GuideSourceType from '../enums/GuideSourceType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logGuidesSwipedParams = {
  park_id?: string;
  source_type: GuideSourceType;
};

/**
 * Fires when a user swipes on the Guides carousel. This event was implemented for the parks page. Note: For volume, we only record the first swipe on this carousel for each visit to the Park page.
 * @param params - Attributes to log with the amplitude event
 * @param params.park_id - Park Id where swipe occurred
 * @param params.source_type - The type of source that the guides were swiped from
 */
const logGuidesSwiped = ({ park_id, source_type }: logGuidesSwipedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('guides swiped', { 'park id': park_id, 'source type': source_type }, callback);
};

export default logGuidesSwiped;
