/**
 * The overview link clicked on the web trail details page.
 */
enum TrailDetailsOverviewLink {
  Accessibility = 'accessibility',
  Alert = 'alert',
  ContactInfo = 'contact info',
  Facilities = 'facilities',
  Fee = 'fee',
  GettingThere = 'getting there',
  Tips = 'tips'
}

export default TrailDetailsOverviewLink;
