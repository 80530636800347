import PageSectionType from '../enums/PageSectionType';
import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logWebTrailLinkClickedParams = {
  page_type?: PageType;
  section_type?: PageSectionType;
};

/**
 * A user clicks a hyperlink to a trail page, not from a trail card.
 * @param params - Attributes to log with the amplitude event
 * @param params.page_type - The type of page on which the link was viewed and clicked
 * @param params.section_type - The section of a page where the link was clicked from: Highlights, Guides, Trail Reviews, FAQ, Top Modules, Link Block
 */
const logWebTrailLinkClicked = ({ page_type, section_type }: logWebTrailLinkClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('web trail link clicked', { 'page type': page_type, 'section type': section_type }, callback);
};

export default logWebTrailLinkClicked;
