import TrailDetailsOverviewLink from '../enums/TrailDetailsOverviewLink';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logLocationPageVisitorLinkClickedParams = {
  location_id: number;
  overview_link_clicked?: TrailDetailsOverviewLink;
};

/**
 * User clicks one of the vistor links on the SEO location page
 * @param params - Attributes to log with the amplitude event
 * @param params.location_id - The location id of the associated location page.
 * @param params.overview_link_clicked - The overview link clicked.
 */
const logLocationPageVisitorLinkClicked = (
  { location_id, overview_link_clicked }: logLocationPageVisitorLinkClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'location page visitor link clicked',
    { 'location id': location_id, 'overview link clicked': overview_link_clicked },
    callback
  );
};

export default logLocationPageVisitorLinkClicked;
