import type { Review as ReviewItem } from 'schema-dts';
import { JsonLdScript } from '@alltrails/core';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import type Review from '@alltrails/core/types/Review';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';

type TrailReviewStructuredDataProps = {
  review: Review;
  localizedText?: string | null;
  languageRegionCode: LanguageRegionCode;
};

const TrailReviewStructuredData = ({ review, localizedText, languageRegionCode }: TrailReviewStructuredDataProps) => (
  <JsonLdScript<ReviewItem>
    data={{
      '@type': 'Review',
      itemReviewed: {
        '@id': wrapUrlSafe(`/trail/${review.trailSlug}`, languageRegionCode),
        '@type': 'LocalBusiness',
        name: review.trailName,
        address: {
          '@type': 'PostalAddress',
          addressLocality: review.trailLocation
        }
      },
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.rating
      },
      reviewBody: localizedText || '',
      author: {
        '@type': 'Person',
        name: fullName(review.user),
        url: wrapUrlSafe(`/members/${review.user.slug}`, languageRegionCode)
      }
    }}
  />
);

export default TrailReviewStructuredData;
