'use client';

import { useState } from 'react';
import { useIntl } from 'react-intl';
import dynamic from 'next/dynamic';
import ChevronSm from '@alltrails/denali/icons/ChevronSm';
import PageType from '@alltrails/amplitude/enums/PageType';
import Link from '@alltrails/denali/components/Link';
import Time from '@alltrails/denali/icons/Time';
import Wallet from '@alltrails/denali/icons/Wallet';
import TrailWheelchair from '@alltrails/denali/icons/TrailWheelchair';
import TrailDetailsOverviewLink from '@alltrails/amplitude/enums/TrailDetailsOverviewLink';
import logLocationPageVisitorLinkClicked from '@alltrails/amplitude/events/logLocationPageVisitorLinkClicked';
import { useMediaQuery } from '@alltrails/core';
import styles from './VisitorInfo.module.scss';
import { ParkSectionLink, ParkSectionRel, VisitorInfoSection } from '@/types/locationDetailsPages/shared';
import { useSelector } from '@/utils/redux';

const relToTrailDetailsOverviewLink = {
  [ParkSectionRel.HoursContact]: TrailDetailsOverviewLink.ContactInfo,
  [ParkSectionRel.PassesFees]: TrailDetailsOverviewLink.Fee,
  [ParkSectionRel.Accessibility]: TrailDetailsOverviewLink.Accessibility
};

const InfoModal = dynamic(() => import('./InfoModal'));

type Props = {
  index: number;
  link: ParkSectionLink;
  pageType: PageType;
  hoursData?: VisitorInfoSection[];
  passesAndFeesData?: VisitorInfoSection[];
  accessibilityData?: VisitorInfoSection[];
  areaId: string;
};

const VisitorInfoCard = ({ index, link, hoursData, passesAndFeesData, accessibilityData, pageType, areaId }: Props) => {
  const intl = useIntl();
  const isMobileBrowser = useSelector((state: { context: { mobileBrowser: boolean } }) => state.context?.mobileBrowser);
  const isTabletOrMobile = useMediaQuery(`(max-width: 1120px)`, isMobileBrowser);
  const [infoModal, setInfoModal] = useState<{ isOpen: boolean; link: ParkSectionLink | null }>({
    isOpen: false,
    link: null
  });

  const infoData = (() => {
    switch (link?.rel) {
      case ParkSectionRel.HoursContact:
        return hoursData;
      case ParkSectionRel.PassesFees:
        return passesAndFeesData;
      case ParkSectionRel.Accessibility:
        return accessibilityData;
      default:
        return undefined;
    }
  })();

  const cardIcon = (() => {
    switch (link?.rel) {
      case ParkSectionRel.PassesFees:
        return <Wallet size="md" />;
      case ParkSectionRel.Accessibility:
        return <TrailWheelchair size="md" />;
      case ParkSectionRel.HoursContact:
        return <Time size="md" />;
      default:
        return undefined;
    }
  })();

  const openInfoModal = () => {
    setInfoModal({ isOpen: true, link });
    logLocationPageVisitorLinkClicked({ location_id: Number(areaId), overview_link_clicked: relToTrailDetailsOverviewLink[link.rel] });
  };

  return (
    <div className={styles.wrapper} key={index}>
      {isTabletOrMobile ? (
        <Link className={styles.mobileClickableCard} onClick={openInfoModal}>
          <div className={styles.mobileCard}>
            <div className={styles.iconContainer}>
              <div className={styles.mobileInfoContainer}>
                {cardIcon && <div className={styles.mobileIconBackground}>{cardIcon}</div>}
                <div className={styles.mobileContent}>
                  <div className={styles.mobileCardTitle}>{link.title}</div>
                  <div className={styles.mobileCardDescription}>
                    {link.rel === ParkSectionRel.Accessibility
                      ? intl.formatMessage({ defaultMessage: 'Get facility info & more' })
                      : link?.description}
                  </div>
                </div>
              </div>
            </div>
            <ChevronSm orientation="right" color="--color-text-secondary" />
          </div>
        </Link>
      ) : (
        <Link className={styles.clickableCard} onClick={openInfoModal} testId={`${link.rel}-link`}>
          <div className={styles.card}>
            <div className={styles.infoContainer}>
              <div className={styles.content}>
                {cardIcon && <div className={styles.iconBackground}>{cardIcon}</div>}
                <div className={styles.titleContainer}>
                  <div className={styles.cardTitle}>
                    {link.title}
                    <ChevronSm orientation="right" color="--color-text-secondary" />
                  </div>
                </div>
              </div>
              <div className={styles.cardDescription}>
                {link.rel === ParkSectionRel.Accessibility ? intl.formatMessage({ defaultMessage: 'Get facility info & more' }) : link?.description}
              </div>
            </div>
          </div>
        </Link>
      )}

      <InfoModal isOpen={infoModal.isOpen} link={infoModal?.link} setInfoModal={setInfoModal} infoData={infoData} pageType={pageType} />
    </div>
  );
};
export default VisitorInfoCard;
