/**
 * The CTA that the user tapped on the trail page (i.e. the link, button, or card that the user clicked).
 */
enum TrailDetailsAction {
  AddToCompleted = 'add to completed',
  AddToList = 'add to list',
  CustomizeMap = 'customize map',
  DownloadInApp = 'download in app',
  DownloadOverflowMenuButton = 'download overflow menu button',
  DownloadRoute = 'download route',
  ExportMapFile = 'export map file',
  GetDirections = 'get directions',
  LiveShareInApp = 'live share in app',
  MapLayer = 'map layer',
  MarkTrailAsComplete = 'mark trail as complete',
  Navigate = 'navigate',
  OpenInApp = 'open in app',
  OpenMap = 'open map',
  ParkOrLocationLinkClicked = 'park or location link clicked',
  PhotoClicked = 'photo clicked',
  Print = 'print',
  RemoveFromCompleted = 'remove from completed',
  Save = 'save',
  SendToGarmin = 'send to garmin',
  Share = 'share',
  ShowAllReviews = 'show all reviews',
  SuggestEdit = 'suggest edit',
  ThreeDMapButton = 'three d map button',
  TrailMapButtonClicked = 'trail map button clicked',
  ViewMap = 'view map',
  ViewPhotos = 'view photos'
}

export default TrailDetailsAction;
