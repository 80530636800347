import LocationPageAlertType from '../enums/LocationPageAlertType';
import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logLocationPageAlertViewedParams = {
  alert_type?: LocationPageAlertType;
  is_high_priority?: boolean;
  page_type?: PageType;
};

/**
 * User sees an alert in the top of a location or park SEO page on web.
 * @param params - Attributes to log with the amplitude event
 * @param params.alert_type - The type of alert viewed.
 * @param params.is_high_priority - True if the alert viewed is high-priority; false if it is low-priority.
 * @param params.page_type - The type of content page from which the section was viewed.
 */
const logLocationPageAlertViewed = ({ alert_type, is_high_priority, page_type }: logLocationPageAlertViewedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'location page alert viewed',
    { 'alert type': alert_type, 'is high priority': is_high_priority, 'page type': page_type },
    callback
  );
};

export default logLocationPageAlertViewed;
