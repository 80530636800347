import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { humanizeTimeSpan } from '@alltrails/shared/utils/timeHelpers';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import InfoDividerDot from '@alltrails/shared/components/cards/InfoDividerDot';
import type ReviewReply from '@alltrails/core/types/ReviewReply';
import Logo from '@alltrails/denali/components/Logo';
import { Typography } from '@alltrails/core';
import Button from '@alltrails/denali/components/Button';
import TextArea from '@alltrails/denali/components/TextArea';
import styles from './styles/styles.module.scss';

const messages = defineMessages({
  POST_PUBLIC_RESPONSE: { defaultMessage: 'Post public response' },
  CANCEL: { defaultMessage: 'Cancel' },
  DELETE: { defaultMessage: 'Delete' },
  RESPONSE: { defaultMessage: 'Response' }
});

export type TrailReviewReplyProps = {
  hideReplies?: boolean;
  isAdmin?: boolean;
  isEditing: boolean;
  isSaving?: boolean;
  languageRegionCode: LanguageRegionCode;
  onCancel: () => void;
  onDelete?: () => void;
  onSave?: (replyText: string) => void;
  reviewReply?: ReviewReply;
};

const TrailReviewReply = ({
  hideReplies,
  isAdmin,
  isEditing,
  isSaving,
  languageRegionCode,
  onCancel,
  onDelete,
  onSave,
  reviewReply
}: TrailReviewReplyProps): JSX.Element => {
  const {
    formattedDefaultMessages: { POST_PUBLIC_RESPONSE, CANCEL, DELETE, RESPONSE }
  } = useFormatMessage(messages);

  const [replyText, setReplyText] = useState(reviewReply?.comment ?? '');

  if (hideReplies || (!isEditing && !reviewReply)) {
    return <></>;
  }

  if (isEditing) {
    return (
      <div className={styles.replyEditContainer} data-at-theme-dimensions="compact">
        <TextArea
          className={styles.replyEditor}
          hideLabel
          labelText={RESPONSE}
          onChange={setReplyText}
          testId="trail-review-reply-text-area"
          value={replyText}
          rows={3}
          helperText={<FormattedMessage defaultMessage="All admin responses will appear as “Response from AllTrails”. Admin names are not shown." />}
        />
        <div className={styles.replyActions}>
          <Button onClick={onCancel} size="sm" testId="trail-review-reply-cancel" text={CANCEL} variant="flat" />
          <Button
            loading={isSaving}
            onClick={() => {
              onSave?.(replyText);
            }}
            size="sm"
            testId="trail-review-reply-save"
            text={POST_PUBLIC_RESPONSE}
            variant="primary"
          />
        </div>
      </div>
    );
  }

  const timeText = humanizeTimeSpan(reviewReply!.metadata.created, languageRegionCode);

  return (
    <div className={styles.replyContainer}>
      <div className={styles.borderBlock} />
      <div className={styles.replyTextContainer}>
        <span>
          <div className={styles.newReviewTitle}>
            <div className={styles.logoBackground}>
              <Logo color="neon" size="sm" variant="symbol" />
            </div>
            <div className={styles.heading}>
              <Typography variant="text200bold">
                <FormattedMessage defaultMessage="Response from AllTrails" />
              </Typography>{' '}
              <div className={styles.subtext}>
                <Typography variant="text100" color="secondary">
                  <span suppressHydrationWarning>{timeText}</span>
                </Typography>
                {isAdmin && (
                  <>
                    <InfoDividerDot />
                    <Typography variant="text100" color="secondary">
                      <span suppressHydrationWarning>
                        <FormattedMessage defaultMessage="Written by" /> {reviewReply?.user?.firstName}
                      </span>
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        </span>
        <span>{reviewReply!.comment}</span>
      </div>
      {isAdmin && (
        <Button
          onClick={() => {
            onDelete?.();
            setReplyText('');
          }}
          size="sm"
          testId="review-reply-delete"
          text={DELETE}
          title={DELETE}
          variant="default"
        />
      )}
    </div>
  );
};

export default TrailReviewReply;
