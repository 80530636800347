/**
 * Locations where user relationships (e.g., follow, unfollow, block, etc.) can be modified with a third-party user.
 */
enum CommunityConnectActionLocation {
  ConnectPage = 'connect page',
  FirstPartyProfileFollowers = 'first party profile followers',
  FirstPartyProfileFollowing = 'first party profile following',
  FollowRequestsConnectList = 'follow requests connect list',
  FollowRequestsViewAll = 'follow requests view all',
  FollowingFeed = 'following feed',
  IndividualFeedPost = 'individual feed post',
  ListCollaborators = 'list collaborators',
  LocationFeed = 'location feed',
  ManageBlockedMembers = 'manage blocked members',
  NewFollowersConnectList = 'new followers connect list',
  NewFollowersViewAll = 'new followers view all',
  NotificationPage = 'notification page',
  PhotoView = 'photo view',
  Reactions = 'reactions',
  SuggestedMembersConnectList = 'suggested members connect list',
  SuggestedMembersFeedCarousel = 'suggested members feed carousel',
  SuggestedMembersViewAll = 'suggested members view all',
  SyncedContactsConnectList = 'synced contacts connect list',
  SyncedContactsViewAll = 'synced contacts view all',
  ThirdPartyProfile = 'third party profile',
  ThirdPartyProfileFollowers = 'third party profile followers',
  ThirdPartyProfileFollowing = 'third party profile following',
  ThirdPartyProfileMutuals = 'third party profile mutuals',
  TrailPageActivity = 'trail page activity',
  TrailPagePhoto = 'trail page photo',
  TrailPageReview = 'trail page review'
}

export default CommunityConnectActionLocation;
